.status {
  position: absolute;
  top: 3rem;
  right: 3rem;
}


@media screen and (max-width: 768px) {
  .status {
    top: 2.1rem;
    right: .4rem;
    z-index: 10;
  }
}

@media screen and (max-width: 430px) {
  .status {
    top: 2.1rem;
    left: -75%;
    right: 0;
    z-index: 1;
  }
}

.pulsating-circle {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 30px;
  height: 30px;
  
  &:before {
    content: '';
    position: relative;
    display: block;
    width: 200%;
    height: 200%;
    box-sizing: border-box;
    margin-left: -50%;
    margin-top: -50%;
    border-radius: 45px;
    // background-color: #e9015e;
    background-color: var(--my-color-var-before);
    animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
  }
  
  &:after {
    content: '';
    position: absolute;
    left: 0; 
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    // background-color: #ed6199;
    background-color: var(--my-color-var-after);
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0,0,0,.3);
    animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -.4s infinite;
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(.1);
  }
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.5);
  }
  50% {
    transform: scale(.7);
  }
  100% {
    transform: scale(.5);
  }
}