.songContainer {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
        width: 20%;
        border-radius: 50%;
    }
    h2 {
        padding: 3rem 1rem 1rem 1rem;
    }
    h3 {
        font-size: 1rem;
    }
}

.song-playing {
    animation: rotation 30s infinite linear;
}

@media screen and (orientation: portrait) and (max-width: 1024px) {
    .songContainer {
        img {
            width: 40%;
        }
    }
}

@media screen and (orientation: landscape) and (max-width: 1024px) {
    .songContainer {
        img {
            width: 30%;
        }
    }
}

@media screen and (max-width: 768px) {
    .songContainer {
        img {
            width: 60%;
        }
    }
}

@media screen and (max-width: 430px) {
    .songContainer {
        h3 {
            max-width: 60%;
        }
    }
}

//set these keyframes anywhere outside selectors so you can import its property

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
