.library {
    position: fixed;
    top: 0;
    left: 0;
    width: 20rem;
    height: 100%;
    background: white;
    box-shadow: 2px 2px 50px rgb(204, 204, 204);
    overflow-y: auto;
    transform: translateX(-100%);
    transition: all 0.5s ease;
    opacity: 0;
    z-index: 2;
    h2 {
        padding: 1.75rem 0 0 1.5rem;
    }
}

.activeLibrary {
    transform: translateX(0%);
    opacity: 1;
}

.libraryHeader {
    position: sticky;
    top: 0;
    z-index: 2;
    background: #fff;
    height: 5rem;
    box-shadow: 0px 4px 4px 0px rgba(157, 164, 164, 0.773);
}

.libraryList {
  margin-top: 2rem;
}

.favorites {
    position: sticky;
    top: 142px;
    background: #fff;
    z-index: 1;
}

.favoritesHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem;
    cursor: pointer;
    h3 {
        padding: 1rem 0 0 2rem;
    }
}

.favoritesCollapse {
    margin-top: 15px;
    margin-right: 2rem;
    color: #6b6b6b;
}

.librarySongsFav {
    max-height: 432px;
    opacity: 1;
    overflow-y: auto;
    transition: all 0.5s ease;
}

.librarySongsFavCollapsed {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 0.5s ease;
}

.favoritesFooter {
    position: sticky;
    height: 8px;
    box-shadow: 0px 2px 2px 0px rgb(204, 204, 204);
}

.collection {
    padding-bottom: 1rem;
    h3, h4 {
      padding: 0 0 0 1rem;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden; 
      width: 220px; 
    }
    a {
        font-size: 12px;
        color: #6b6b6b;
        cursor: pointer;
        margin: 0 0 0 1rem;
        transition: all 0.3s ease;
        &:hover {
            // background: rgb(65, 65, 65);
            color: rgb(0, 0, 0);
        }
    }
}

.librarySong {
    display: flex;
    align-items: center;
    padding: 1rem 1rem;
    transition: background 0.5s ease;
    &:hover {
        background: rgb(178, 228, 252);
    }
}

.textContainer {
    width: 100%;
}

.textStarDivider {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.favoriteStar {
    color: rgb(155, 155, 155);
}

.favoriteStarSelected {
    color: rgb(255, 238, 0);
}

.songDescription {
    padding: 0.75rem;
    h3 {
        font-size: 1rem;
    }
    h4 {
        font-size: 0.75rem;
    }
}

.selected {
    background: rgb(132, 203, 236);
}

* {
    scrollbar-width: thin;
    scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}
*::-webkit-scrollbar {
    width: 5px;
}
*::-webkit-scrollbar-track {
    background: transparent;
}
*::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 20px;
    border: transparent;
}

@media screen and (orientation: portrait) and (max-width: 767px) {
    .library {
        width: 100%;
    }
}

.search-form {
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.search-bar {
    width: 100%;
    padding: 8px;
    margin-left: 2rem;
    margin-right: 2.7rem;
}

